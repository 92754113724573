import React from "react";
import { Sidenav, Nav } from "rsuite";
import DashboardIcon from "@rsuite/icons/Dashboard";
import PeoplesIcon from "@rsuite/icons/Peoples";
import { useNavigate } from "react-router-dom";
import PeopleBranchIcon from "@rsuite/icons/PeopleBranch";
import OperatePeopleIcon from "@rsuite/icons/OperatePeople";
import ModelIcon from "@rsuite/icons/Model";
import PeoplesMapIcon from "@rsuite/icons/PeoplesMap";
import { useSelector } from "react-redux";
const styles = {
  width: 240,
  display: "inline-table",
  marginRight: 10,
};

const Sidebar = ({
  appearance,
  openKeys,
  expanded,
  onOpenChange,
  onExpand,
  ...props
}) => {
  const displayName = useSelector(
    (state) => state.nameReducer.user.userDetails
  );
  const Type = displayName.Type;
  const newFunction = () => {
    onExpand(!expanded);
  };
  const navigate = useNavigate();
  return (
    <div style={styles}>
      <Sidenav appearance={appearance} defaultOpenKeys={openKeys}>
        <Sidenav.Body>
          <Nav {...props}>
            {Type === "Admin" && (
              <>
                {" "}
                <Nav.Item
                  eventKey="1"
                  icon={<DashboardIcon />}
                  onClick={() => navigate("Dashboard")}
                >
                  Dashboard
                </Nav.Item>
                <Nav.Item
                  eventKey="2"
                  icon={<PeoplesIcon />}
                  onClick={() => navigate("ManageUsers")}
                >
                  List Users
                </Nav.Item>
                <Nav.Menu
                  eventKey="3"
                  title="Daily Trasactions"
                  icon={<ModelIcon />}
                >
                  <Nav.Item eventKey="3-1" onClick={() => navigate("AddNew")}>
                    Add New
                  </Nav.Item>
                  <Nav.Item eventKey="3-2" onClick={() => navigate("ViewAll")}>
                    View All
                  </Nav.Item>
                </Nav.Menu>
                <Nav.Menu
                  eventKey="4"
                  title="Client"
                  icon={<PeopleBranchIcon />}
                >
                  {/* <Nav.Item eventKey="4-1" onClick={() => navigate("Clients")}>
                    Manage Clients
                  </Nav.Item> */}
                  <Nav.Item
                    eventKey="4-2"
                    onClick={() => navigate("ClientNewTransaction")}
                  >
                    New Entry
                  </Nav.Item>
                  <Nav.Item
                    eventKey="4-3"
                    onClick={() => navigate("ViewClients")}
                  >
                    Ledger
                  </Nav.Item>
                </Nav.Menu>
                <Nav.Menu
                  eventKey="5"
                  title="Supplier"
                  icon={<OperatePeopleIcon />}
                >
                  {/* <Nav.Item
                    eventKey="5-1"
                    onClick={() => navigate("Suppliers")}
                  >
                    Manage Suppliers
                  </Nav.Item> */}
                  <Nav.Item
                    eventKey="5-1"
                    onClick={() => navigate("SupplierNewTransaction")}
                  >
                    New Entry
                  </Nav.Item>
                  <Nav.Item
                    eventKey="5-2"
                    onClick={() => navigate("ViewSuppliers")}
                  >
                    Ledger
                  </Nav.Item>
                </Nav.Menu>
              </>
            )}

            <Nav.Item
              eventKey="6"
              icon={<PeoplesIcon />}
              onClick={() => navigate("GSTCalculation")}
            >
              GST Details
            </Nav.Item>
            <Nav.Item
              eventKey="7"
              icon={<PeoplesIcon />}
              onClick={() => navigate("Inputs")}
            >
              Manage Lists
            </Nav.Item>
            {/* <Nav.Item
              eventKey="8"
              icon={<PeoplesIcon />}
              onClick={() => navigate("TestPDF")}
            >
              TestPDF
            </Nav.Item> */}
          </Nav>
        </Sidenav.Body>
        <Sidenav expanded={expanded} onToggle={newFunction} />
      </Sidenav>
    </div>
  );
};

export default Sidebar;
