import React, { useCallback, useState } from "react";
import {
  Button,
  DatePicker,
  Input,
  Form,
  InputGroup,
  AutoComplete,
  Modal,
  SelectPicker,
  useToaster,
  Notification,
  IconButton,
  Toggle,
  Stack,
} from "rsuite";
import Header from "../component/Header";
import Table from "react-bootstrap/Table";
import TrashIcon from "@rsuite/icons/Trash";
import ReloadIcon from "@rsuite/icons/Reload";
import axios from "axios";
import AddOutlineIcon from "@rsuite/icons/AddOutline";

import DetailIcon from "@rsuite/icons/Detail";

/* import { BorderColor } from "@mui/icons-material"; */
import moment from "moment";
import { filterParams, number2text, Table2XLSXNew } from "../functions";
import AgGrid from "../component/AgGrid";
import useAPINew from "../Hooks/useAPINew";
import axios2 from "../api/axios2";
import { PaymentMode } from "../component/data";
const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));
const AddSupplierTransaction = () => {
  const usersList = useAPINew("GetSupervisorUsers.php");
  //const receiverList = useAPINew("GetAllBillerNew.php", "Get");
  // const siteList = useAPINew("GetSitesForDropdown.php", "Get");
  const SupplierList = useAPINew("GetSupplierNew.php", "Get");
  //const [SupplierList, setSupplierList] = useState([]);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const handleViewModalOpen = () => setOpenDrawer(true);
  const formatNumber = (number) => {
    return "₹ " + Math.floor(number).toLocaleString();
  };
  const currencyFormatter = (params) => {
    //return formatNumber(params.value);
    return formatNumber(params.value);
  };
  const handleViewModalClose = () => {
    setOpenDrawer(false);
  };
  /* const [open, setOpen] = React.useState(false); */
  const [open1, setOpen1] = React.useState(false);
  const [processing, setProcessing] = useState(false);
  const [processing1, setProcessing1] = useState(false);
  const [dataList, setDataList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [size, setSize] = useState("md");
  const toggle = useCallback((props) => {
    setLoading(true);

    setTimeout(() => {
      setChecked((checked) => !checked);
      setLoading(false);
    }, 1000);

    return (
      <Toggle
        loading={loading}
        checked={checked}
        onChange={toggle}
        {...props}
      />
    );
  }, []);

  const [value, setValue] = React.useState({
    SupplierName: "",
    SupplierID: "",
    Date: new Date(),
    Type: "",
    Amount: "",
    GST: "",
    Note: "",
  });
  const toaster = useToaster();
  /* const [column, setColumn] = useState([
    { field: "SupplierName", title: "Supplier Name" },
    { field: "Date", title: "Date" },
    { field: "Amount", title: "Amount" },
    { field: "GST", title: "GST" },
    { field: "Type", title: "Type" },
    { field: "Note", title: "Note" },
  ]); */

  const column = React.useMemo(
    () => [
      { field: "SupplierName", title: "Supplier Name", minWidth: 300 },
      {
        field: "Date",
        title: "Date",
        type: "date",
        filter: "agDateColumnFilter",
        editable: false,
        filterParams: filterParams,
      },
      {
        field: "Credit",
        title: "Credit",
        valueFormatter: currencyFormatter,
        cellStyle: (params) => {
          if (params.value > 0) {
            //mark police cells as red
            return { color: "green", fontWeight: "bold" };
          } else {
            //mark police cells as red
            return { color: "white", fontWeight: "bold" };
          }
        },
      },
      {
        field: "Debit",
        title: "Debit",
        valueFormatter: currencyFormatter,
        cellStyle: (params) => {
          if (params.value > 0) {
            //mark police cells as red
            return { color: "red", fontWeight: "bold" };
          } else {
            //mark police cells as red
            return { color: "white", fontWeight: "bold" };
          }
        },
      },
      { field: "GST", title: "GST", valueFormatter: currencyFormatter },
      {
        field: "Amount",
        headerName: "Amount",
        valueFormatter: currencyFormatter,
      },
      { field: "Sitename", title: "Sitename" },
      { field: "Note", title: "Note" },
    ],
    []
  );

  //const [usersList, setUsersList] = useState([]);
  const [selectedName, setselectedName] = useState("");
  const [selectedNameID, setselectedNameID] = useState("");
  const [billCopy, setBillCopy] = useState("");
  const [sitename, setSitename] = useState("");
  const [films1, setFilms1] = useState([]);
  const [numberText, setNumberText] = useState("");
  function compare(a, b) {
    let nameA = a.toUpperCase();
    let nameB = b.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }
  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) setNumberText(number2text(value.Amount));
    return () => {
      isSubscribed = false;
    };
  }, [value.Amount]);
  const getList = () => {
    //RetriveSiteName()
    axios
      .get("https://api.laxmaninterior.in/GetSiteName.php")
      .then((a) => {
        const result = a.data;
        if (result.status === 1) {
          //console.log(result.Data.map(({SiteName})=>SiteName))
          const test = result.Data.map(({ SiteName }) => SiteName);
          setFilms1(test);
          // console.log(result.Data)
        }
        if (result.status === 0) {
          setFilms1([]);
        }
        //console.log(result);
      })
      .catch((a) => {
        console.log(a);
      });
  };

  /*   const getUsersList = () => {
    setUsersList([]);
    axios
      .get("https://api.laxmaninterior.in/GetUsersForDropDown.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setUsersList(data1.Data);
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }; */

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      //getUsersList();
      getList();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  /* const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  }; */
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) handleRefresh();
    return () => {
      isSubscribed = false;
    };
  }, []);

  const handleRefresh = () => {
    //getSupplierList();
    axios2
      .get("https://api.laxmaninterior.in/GetAllSupplierTransaction.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setDataList(data1.Data);
        }
        if (data1.status === 0) {
        }
        setProcessing(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing(false);
      });
    setProcessing(false);
  };

  const SaveData = () => {
    const formData = new FormData();
    formData.append("File", billCopy);
    setProcessing(true);

    if (value.SupplierName === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter Supplier name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (value.Type === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter transaction type
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    if (value.Amount === "" || value.Amount === 0) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter valid amount
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (value.Date === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter date
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    if (checked && billCopy === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select bill copy
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    try {
      if (checked) {
        setProcessing1(true);
        axios
          .post("https://downloads.laxmaninterior.in/AddBill.php", formData)
          .then((response) => {
            const result = response.data;
            setProcessing1(false);
            if (result.status === 1) {
              console.log(value);
              axios
                .post(
                  "https://api.laxmaninterior.in/AddSupplierTransactions.php",
                  {
                    ...value,
                    SiteName: sitename,
                    Bill: result.Filename,
                    PaidBy: selectedNameID,
                  }
                )
                .then((response) => {
                  const result = response.data;
                  console.log(result);
                  if (result.status === 1) {
                    toaster.push(
                      <Notification
                        type="success"
                        header="success"
                        duration={1000}
                      >
                        {result.Success}
                      </Notification>,
                      { placement: "topEnd" }
                    );
                    handleRefresh();
                  }
                  if (result.status === 0) {
                    toaster.push(
                      <Notification type="error" header="Error" duration={1000}>
                        {result.error}
                      </Notification>,
                      { placement: "topEnd" }
                    );
                  }

                  setProcessing(false);
                })
                .catch(function (error) {
                  console.log(error);
                });
            }
            if (result.status === 0) {
              toaster.push(
                <Notification type="error" header="Error" duration={1000}>
                  {`Error occured - ${result.Error}`}
                </Notification>,
                { placement: "topEnd" }
              );
              setProcessing1(false);
            }
          })
          .catch(function (error) {
            console.log(error);
            setProcessing1(false);
          });
      } else {
        setProcessing(true);
        axios2
          .post("AddSupplierTransactions.php", {
            ...value,
            SiteName: sitename,
            PaidBy: selectedNameID,
            Date: moment(value.Date).format("YYYY-MM-DD"),
          })
          .then((response) => {
            const result = response.data;
            console.log(result);
            if (result.status === 1) {
              toaster.push(
                <Notification type="success" header="success" duration={1000}>
                  {result.Success}
                </Notification>,
                { placement: "topEnd" }
              );
              handleRefresh();
            }
            if (result.status === 0) {
              toaster.push(
                <Notification type="error" header="Error" duration={1000}>
                  {result.error}
                </Notification>,
                { placement: "topEnd" }
              );
            }

            setProcessing(false);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setProcessing(false);
      setProcessing1(false);
    }
  };

  return (
    <div>
      <Header>
        <h4 style={{ color: "white", fontSize: 25 }}>Add New</h4>
      </Header>
      <div className="Subbody">
        <div style={{ flexDirection: "column" }}>
          <Stack spacing={4}>
            <SelectPicker
              data={SupplierList.data}
              cleanable={false}
              appearance="default"
              placeholder="Select Supplier"
              style={{ width: 224 }}
              value={value.value}
              onSelect={(e, Item) => {
                setValue((a) => {
                  return {
                    ...a,
                    SupplierName: Item.value,
                    SupplierID: Item.id,
                  };
                });
              }}
            />
            <DatePicker
              oneTap
              cleanable={false}
              appearance="default"
              format="dd-MM-yyyy"
              placeholder="Select Date"
              style={{ width: 120 }}
              value={value.Date}
              onChange={(e) => {
                setValue((a) => {
                  return { ...a, Date: e };
                });
              }}
            />
            <SelectPicker
              cleanable={false}
              data={[
                { id: 1, value: "Credit", label: "Credit" },
                { id: 2, value: "Debit", label: "Debit" },
              ]}
              searchable={false}
              appearance="subtle"
              placeholder="Credit/Debit"
              style={{
                width: 150,
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "#eee",
                outline: "none",
              }}
              value={value.Type}
              onChange={(e) => {
                setValue((a) => {
                  return { ...a, Type: e };
                });
              }}
            />
            <InputGroup style={{ width: "200px" }}>
              <InputGroup.Addon>(₹)</InputGroup.Addon>
              <Input
                placeholder="Amount Excl GST"
                value={value.Amount}
                onChange={(e) => {
                  setValue((a) => {
                    return { ...a, Amount: e };
                  });
                }}
              />
            </InputGroup>
            <InputGroup style={{ width: "200px" }}>
              <InputGroup.Addon>GST (₹)</InputGroup.Addon>
              <Input
                placeholder="GST"
                value={value.GST}
                onChange={(e) => {
                  setValue((a) => {
                    return { ...a, GST: e };
                  });
                }}
              />
            </InputGroup>
            <SelectPicker
              cleanable={false}
              data={PaymentMode}
              appearance="default"
              placeholder="Select payment mode"
              searchable={false}
              style={{ width: 200 }}
              value={value.Mode}
              onChange={(e) => {
                setValue((a) => {
                  return { ...a, Mode: e };
                });
              }}
            />
            {/* Info: ===================================================== */}
          </Stack>
          <hr></hr>
          <Stack spacing={6}>
            <SelectPicker
              data={usersList.data}
              appearance="default"
              cleanable={false}
              placeholder="Select from list"
              defaultValue={selectedName}
              style={{ width: 200 }}
              key="id"
              sort={() => {
                return (a, b) => {
                  return compare(a.value, b.value);
                };
              }}
              onSelect={(e, Item) => {
                setselectedName(e);
                setselectedNameID(Item.id);
              }}
              value={selectedName}
            />
            <AutoComplete
              data={films1}
              value={sitename}
              onChange={(e) => setSitename(e)}
              style={{ width: 200 }}
              placeholder="Enter Site Name..."
            />
            <Toggle
              checked={checked}
              checkedChildren="Bill"
              unCheckedChildren="No Billl"
              onChange={(e) => {
                setChecked(e);
                console.log(e);
              }}
              size={size}
            />
            {checked && (
              <input
                type="file"
                name="file"
                onChange={(e) => setBillCopy(e.target.files[0])}
              ></input>
            )}
            <Button appearance="link" onClick={handleOpen1}>
              <DetailIcon style={{ margin: "5px" }} />{" "}
            </Button>
            {!processing1 && (
              <IconButton
                color="green"
                disabled={processing1}
                appearance="primary"
                onClick={SaveData}
                {...(processing1 ? "loading" : null)}
                icon={<AddOutlineIcon />}
              >
                Add
              </IconButton>
            )}

            <div>{numberText}</div>
          </Stack>
        </div>
        <Modal open={open1} onClose={handleClose1} size="xs">
          <Modal.Header>
            <Modal.Title>New Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form fluid>
              <Form.Group controlId="textarea-9">
                <Form.ControlLabel>Descriptions</Form.ControlLabel>
                <Form.Control
                  rows={5}
                  name="textarea"
                  accepter={Textarea}
                  style={{ resize: "none" }}
                  value={value.Note}
                  onChange={(e) => {
                    setValue((a) => {
                      return { ...a, Note: e };
                    });
                  }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose1} appearance="primary">
              Submit
            </Button>
            <Button onClick={handleClose1} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Header>
        <h5 style={{ color: "white", fontSize: 20 }}>
          {`List of Transactions (${dataList.length}) - ${moment().format(
            "MMM-YYYY"
          )} `}{" "}
        </h5>
      </Header>
      <div className="InputContainer">
        <AgGrid
          rowData={dataList}
          columns={column}
          onGridReady={handleRefresh}
          onRowSelectedData={setSelectedRowData}
          handleViewModalOpen={handleViewModalOpen}
          showRefresh={true}
          setRowStyle={true}
        />
      </div>
    </div>
  );
};

export default AddSupplierTransaction;
