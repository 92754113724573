import React, { useCallback, useState } from "react";
import {
  DateRangePicker,
  useToaster,
  Notification,
  IconButton,
  Stack,
  Toggle,
  Form,
  Button,
  Row,
  Col,
  Input,
  InputGroup,
  SelectPicker,
  DatePicker,
  Grid,
} from "rsuite";
import Header from "../component/Header";
import SearchIcon from "@rsuite/icons/Search";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { filterParams } from "../functions";
import AgGrid from "../component/AgGrid";
import { addDays } from "date-fns";
import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import SideDrawer from "../component/SideDrawer";
import Plus from "@rsuite/icons/Plus";
import { AdminID } from "../Constants";

//import { startOfToday } from "date-fns";
function getLastFinancialYearDates() {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();

  let startOfLastFY, endOfLastFY;

  if (currentMonth >= 3) {
    // From April to December
    startOfLastFY = new Date(currentYear - 1, 3, 1); // April 1st of last year
    endOfLastFY = new Date(currentYear, 2, 31); // March 31st of this year
  } else {
    // From January to March
    startOfLastFY = new Date(currentYear - 2, 3, 1); // April 1st two years ago
    endOfLastFY = new Date(currentYear - 1, 2, 31); // March 31st of last year
  }

  return {
    startOfLastFY,
    endOfLastFY,
  };
}

const { startOfLastFY, endOfLastFY } = getLastFinancialYearDates();

function getCurrentFinancialYearDates() {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();

  let startOfCurrentFY, endOfCurrentFY;

  if (currentMonth >= 3) {
    // From April to December
    startOfCurrentFY = new Date(currentYear, 3, 1); // April 1st of this year
    endOfCurrentFY = new Date(currentYear + 1, 2, 31); // March 31st of next year
  } else {
    // From January to March
    startOfCurrentFY = new Date(currentYear - 1, 3, 1); // April 1st of last year
    endOfCurrentFY = new Date(currentYear, 2, 31); // March 31st of this year
  }

  return {
    startOfCurrentFY,
    endOfCurrentFY,
  };
}

const { startOfCurrentFY, endOfCurrentFY } = getCurrentFinancialYearDates();

const predefinedRanges = [
  {
    label: "Today",
    value: [new Date(), new Date()],
  },
  {
    label: "Yesterday",
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
  },
  {
    label: "This week",
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
  },
  {
    label: "Last 7 days",
    value: [subDays(new Date(), 6), new Date()],
  },
  {
    label: "Last 30 days",
    value: [subDays(new Date(), 29), new Date()],
  },
  {
    label: "Last Fin Year",
    value: [startOfLastFY, endOfLastFY],
  },
  {
    label: "Current Fin Year",
    value: [startOfCurrentFY, endOfCurrentFY],
  },
  {
    label: "This month",
    value: [startOfMonth(new Date()), endOfMonth(new Date())],
  },
  {
    label: "Last month",
    value: [
      startOfMonth(addMonths(new Date(), -1)),
      endOfMonth(addMonths(new Date(), -1)),
    ],
  },
];
const GSTCalculation = () => {
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDrawer1, setOpenDrawer1] = useState(false);
  const [credit, setCredit] = useState(0);
  const [debit, setDebit] = useState(0);
  const [balance, setBalance] = useState(0);
  const [dataList2, setdataList2] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [data, setData] = useState({ Date: new Date() });
  const [editMode, setEditMode] = React.useState(false);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleViewModalOpen = () => setOpenDrawer(true);

  const handleViewModalClose = () => {
    setOpenDrawer(false);
    setData({ Date: new Date() });
  };
  const handleViewModalOpen1 = () => setOpenDrawer1(true);
  const handleViewModalClose1 = () => {
    setOpenDrawer1(false);
    setData({ Date: new Date() });
  };
  const toggle = useCallback((props) => {
    setLoading(true);

    setTimeout(() => {
      setChecked((checked) => !checked);
      setLoading(false);
    }, 1000);

    return (
      <Toggle
        loading={loading}
        checked={checked}
        onChange={toggle}
        {...props}
      />
    );
  }, []);
  const [processing2, setProcessing2] = useState(false);
  //const [siteList, setSiteList] = useState([]);

  const toaster = useToaster();
  const [selectedDateRange, setselectedDateRange] = useState([
    new Date(),
    new Date(),
  ]);

  React.useEffect(() => {
    console.log(selectedRowData);
    let isSubscribed = true;
    if (isSubscribed)
      if (selectedRowData?.SrNo) {
        setChecked(false);
        // GetDiscussionByID(selectedRowData.UniqueID);
        var dateParts = selectedRowData?.Date.split("/");

        // month is 0-based, that's why we need dataParts[1] - 1

        var dateObject = new Date(
          dateParts[2],
          dateParts[1] - 1,
          +dateParts[0]
        );

        const newData = { ...selectedRowData, Date: new Date(dateObject) };
        setData(newData);
      }
    return () => {
      isSubscribed = false;
    };
  }, [selectedRowData?.SrNo, selectedRowData]);

  const SaveData = async () => {
    setProcessing(true);
    if (data.Amount === "" || data.Amount === 0) {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Enter Amount
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    //console.log(uuidv4());
    const datatoSend = {
      Name: data.Name,
      Date: moment(data.Date).format("YYYY-MM-DD"),
      Amount: data.Amount,
      GST: data.GST,
      Notes: `${data.Notes}`,
      Type: `${data.Type}`,
      SavedBy: AdminID,
    };
    try {
      console.log(datatoSend);
      axios
        .post(
          "https://api.laxmaninterior.in/AddOtherGSTTransaction.php",
          datatoSend,
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((response) => {
          const result = response.data;
          console.log(result);
          if (result.status === 1) {
            toaster.push(
              <Notification type="success" header="success" duration={1000}>
                Saved successfully
              </Notification>,
              { placement: "topEnd" }
            );
            setData({ Date: new Date() });
            setOpenDrawer(false);
            handleSearchforSupplierByDate();
          }
          if (result.status === 0) {
            toaster.push(
              <Notification type="error" header="Error" duration={1000}>
                {result.Error}
              </Notification>,
              { placement: "topEnd" }
            );
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Error occured: {e.Message}
        </Notification>,
        { placement: "topEnd" }
      );
    } finally {
      setProcessing(false);
    }
  };

  const UpdateData = async () => {
    setProcessing(true);
    if (data.Amount === "" || data.Amount === 0) {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Enter Amount
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    if (data.SrNo === "" || data.SrNo === 0) {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Invalid ID
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (data.Source !== "Other") {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Invalid request
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    //console.log(uuidv4());
    const datatoSend = {
      Name: data.Name,
      Date: moment(data.Date).format("YYYY-MM-DD"),
      Amount: data.Amount,
      GST: data.GST,
      Notes: `${data.Notes}`,
      Type: `${data.Type}`,
      SrNo: data.SrNo,
    };
    try {
      console.log(datatoSend);
      axios
        .post(
          "https://api.laxmaninterior.in/EditOtherGSTTransaction.php",
          datatoSend,
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((response) => {
          const result = response.data;
          console.log(result);
          if (result.status === 1) {
            toaster.push(
              <Notification type="success" header="success" duration={1000}>
                Saved successfully
              </Notification>,
              { placement: "topEnd" }
            );
            setData({ Date: new Date() });
            setOpenDrawer(false);
            handleSearchforSupplierByDate();
          }
          if (result.status === 0) {
            toaster.push(
              <Notification type="error" header="Error" duration={1000}>
                {result.Error}
              </Notification>,
              { placement: "topEnd" }
            );
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Error occured: {e.Message}
        </Notification>,
        { placement: "topEnd" }
      );
    } finally {
      setProcessing(false);
    }
  };

  const formatNumber = (number) => {
    return "₹ " + Math.floor(number).toLocaleString();
  };

  const currencyFormatter = (params) => {
    //return formatNumber(params.value);
    return formatNumber(params.value);
  };

  const column2 = React.useMemo(
    () => [
      {
        field: "Date",
        title: "Date",
        type: "date",
        filter: "agDateColumnFilter",
        editable: false,
        filterParams: filterParams,
      },
      { field: "Name", title: "Name", minWidth: 300 },
      {
        field: "Amount",
        title: "Amount",
        valueFormatter: currencyFormatter,
        cellStyle: (params) => {
          if (params.value === 0) {
            //mark police cells as red
            return { color: "white", fontWeight: "bold" };
          }
        },
      },

      {
        field: "GST",
        title: "GST",
        valueFormatter: currencyFormatter,
        cellStyle: (params) => {
          if (params.value === 0) {
            //mark police cells as red
            return { color: "white", fontWeight: "bold" };
          }
        },
      },
      {
        field: "Total",
        title: "Total",
        valueFormatter: currencyFormatter,
        cellStyle: (params) => {
          if (params.value === 0) {
            //mark police cells as red
            return { color: "white", fontWeight: "bold" };
          }
        },
      },
      { field: "Notes", title: "Notes" },
      { field: "Type", title: "Type" },
    ],
    []
  );

  const handleDelete = (data = { Name: "", Date: "" }) => {
    if (selectedRowData.SrNo === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Something went wrong.
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    confirmAlert({
      title: "Confirm?",
      message: `Are you sure to delete ${data.Name}'s Payment on ${data.Date}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const datatoSend = { ID: selectedRowData.SrNo };
            //console.log(datatoSend);
            axios
              .post(
                "https://api.laxmaninterior.in/DeleteFromOther.php",
                datatoSend
              )
              .then(function (response) {
                const data1 = response.data;
                if (data1.status === 1) {
                  toaster.push(
                    <Notification
                      type="success"
                      header="Deleted"
                      duration={1000}
                    >
                      Deleted successfully
                    </Notification>,
                    { placement: "topEnd" }
                  );
                }
                if (data1.status === 0) {
                  toaster.push(
                    <Notification type="error" header="Error" duration={1000}>
                      Something went wrong. Please refresh and try again later.
                    </Notification>,
                    { placement: "topEnd" }
                  );
                }
              })
              .catch(function (error) {
                console.log(error);
                toaster.push(
                  <Notification type="error" header="Error" duration={1000}>
                    {error}
                  </Notification>,
                  { placement: "topEnd" }
                );
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleSearchforSupplierByDate = () => {
    setProcessing2(true);
    setdataList2([]);
    if (selectedDateRange.length < 2) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter Supplier name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    const datatoSend = {
      StartDate: moment(selectedDateRange[0]).format("YYYY-MM-DD"),
      EndDate: moment(selectedDateRange[1]).format("YYYY-MM-DD"),
    };
    axios
      .post("https://api.laxmaninterior.in/GetGSTDetails.php", datatoSend)
      .then(function (response) {
        const data1 = response.data;
        //console.log(data1);

        if (data1.status === 1) {
          data1.Data.forEach((r) => {
            setdataList2(data1.Data);
            setCredit(data1.Credit);
            setDebit(data1.Debit);
            setBalance(data1.Balance);
          });
        }
        if (data1.status === 0) {
          setCredit(0);
          setDebit(0);
          setBalance();
        }
        setProcessing2(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing2(false);
      });
  };

  return (
    <div>
      <Header>
        <h4 style={{ color: "white", fontSize: 25 }}>GST Ledger Ledger</h4>
      </Header>
      <div className="InputContainer">
        <Form layout="inline">
          <Stack spacing={10}>
            <Stack spacing={4}>
              <DateRangePicker
                placeholder="Select Date Range"
                cleanable={false}
                value={selectedDateRange}
                onChange={setselectedDateRange}
                ranges={predefinedRanges}
              />
              &nbsp;
              <IconButton
                onClick={handleSearchforSupplierByDate}
                loading={processing2}
                icon={<SearchIcon />}
              >
                Search
              </IconButton>
              <IconButton
                onClick={handleViewModalOpen}
                loading={processing2}
                icon={<Plus />}
              >
                Add new
              </IconButton>
            </Stack>
            <Stack spacing={4}>
              <div>
                <span style={{ fontWeight: "bold" }}>Credit : </span>{" "}
                {`${Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(credit)}`}
              </div>
              &nbsp; &nbsp;
              <div>
                <span style={{ fontWeight: "bold" }}>Debit : </span>{" "}
                {`${Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(debit)}`}
              </div>
              &nbsp; &nbsp;
              <div>
                <span style={{ fontWeight: "bold" }}>Balance : </span>{" "}
                <span
                  style={{
                    width: "200px",
                    color: `${balance > 0 ? "red" : "green"}`,
                  }}
                >
                  {balance > 0
                    ? `${Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 2,
                      }).format(balance)}`
                    : `${Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 2,
                      }).format(balance * -1)}`}
                </span>
              </div>
            </Stack>
          </Stack>
        </Form>
      </div>
      <div className="InputContainer">
        <AgGrid
          rowData={dataList2}
          columns={column2}
          onGridReady={handleSearchforSupplierByDate}
          setRowStyle={true}
          onRowSelectedData={setSelectedRowData}
          Source="GST"
          handleViewModalOpen={handleViewModalOpen1}
        />
      </div>
      <SideDrawer
        open={openDrawer}
        onClose={handleViewModalClose}
        title="New Transaction"
      >
        <div className="row">
          <Grid fluid style={{ padding: 1 }} gutter={4}>
            <Row className="show-grid">
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Date : </label>
                  <br />
                  <DatePicker
                    cleanable={false}
                    appearance="default"
                    placeholder="Select Date"
                    oneTap
                    format="dd-MM-yyyy"
                    style={{ width: "50%", fontSize: 17 }}
                    value={data.Date}
                    onChange={(e) => {
                      setData((a) => {
                        return {
                          ...a,
                          Date: e,
                        };
                      });
                    }}
                  />
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Name : </label>
                  <Input
                    value={data.Name}
                    onChange={(e) => {
                      setData((a) => {
                        return {
                          ...a,
                          Name: e,
                        };
                      });
                    }}
                    style={{ padding: 5, outline: "none" }}
                    placeholder="Enter Name"
                  />
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Type : </label>
                  <br />
                  <SelectPicker
                    cleanable={false}
                    data={[
                      { id: 1, value: "Credit", label: "Credit" },
                      { id: 2, value: "Debit", label: "Debit" },
                    ]}
                    searchable={false}
                    appearance="subtle"
                    placeholder="Credit/Debit"
                    style={{
                      width: 150,
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderColor: "#eee",
                      outline: "none",
                    }}
                    value={data?.Type}
                    onChange={(e) => {
                      setData((a) => {
                        return { ...a, Type: e };
                      });
                    }}
                  />
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Amount Excl GST : </label>
                  <br />
                  <InputGroup style={{ width: "250px" }}>
                    <InputGroup.Addon>(₹)</InputGroup.Addon>
                    <Input
                      placeholder="Amount Excl GST"
                      value={data.Amount}
                      onChange={(e) => {
                        setData((a) => {
                          return { ...a, Amount: e };
                        });
                      }}
                    />
                  </InputGroup>
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Date : </label>
                  <br />
                  <InputGroup style={{ width: "250px" }}>
                    <InputGroup.Addon>GST (₹)</InputGroup.Addon>
                    <Input
                      placeholder="GST"
                      value={data.GST}
                      onChange={(e) => {
                        setData((a) => {
                          return { ...a, GST: e };
                        });
                      }}
                    />
                  </InputGroup>
                </Row>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Notes : </label>
                  <Input
                    value={data.Notes}
                    onChange={(e) => {
                      setData((a) => {
                        return {
                          ...a,
                          Notes: e,
                        };
                      });
                    }}
                    style={{ padding: 5, outline: "none" }}
                    placeholder="Enter Notes"
                  />
                </Row>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <>
                    <Button
                      style={{ padding: "10px 12px 10px 12px", width: 100 }}
                      appearance="primary"
                      color="blue"
                      onClick={SaveData}
                    >
                      Save
                    </Button>
                  </>
                  &nbsp; &nbsp;
                  {editMode && (
                    <Button
                      style={{ padding: "10px 12px 10px 12px", width: 100 }}
                      appearance="primary"
                      color="red"
                      onClick={() => {
                        setEditMode(false);
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                </Row>
              </Col>
            </Row>
          </Grid>
        </div>
      </SideDrawer>
      <SideDrawer
        open={openDrawer1}
        onClose={handleViewModalClose1}
        title="View / Edit Transaction"
      >
        <div className="row">
          <Grid fluid style={{ padding: 1 }} gutter={4}>
            <Row className="show-grid">
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Date : </label>
                  <br />
                  <DatePicker
                    readOnly={!editMode}
                    cleanable={false}
                    appearance="default"
                    placeholder="Select Date"
                    oneTap
                    format="dd-MM-yyyy"
                    style={{ width: "50%", fontSize: 17 }}
                    value={data.Date}
                    onChange={(e) => {
                      setData((a) => {
                        return {
                          ...a,
                          Date: e,
                        };
                      });
                    }}
                  />
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Name : </label>
                  <Input
                    value={data.Name}
                    readOnly={!editMode}
                    onChange={(e) => {
                      setData((a) => {
                        return {
                          ...a,
                          Name: e,
                        };
                      });
                    }}
                    style={{ padding: 5, outline: "none" }}
                    placeholder="Enter Name"
                  />
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Type : </label>
                  <br />
                  <SelectPicker
                    readOnly={!editMode}
                    cleanable={false}
                    data={[
                      { id: 1, value: "Credit", label: "Credit" },
                      { id: 2, value: "Debit", label: "Debit" },
                    ]}
                    searchable={false}
                    appearance="subtle"
                    placeholder="Credit/Debit"
                    style={{
                      width: 150,
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderColor: "#eee",
                      outline: "none",
                    }}
                    value={data?.Type}
                    onChange={(e) => {
                      setData((a) => {
                        return { ...a, Type: e };
                      });
                    }}
                  />
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Amount Excl GST : </label>
                  <br />
                  <InputGroup style={{ width: "250px" }}>
                    <InputGroup.Addon>(₹)</InputGroup.Addon>
                    <Input
                      readOnly={!editMode}
                      placeholder="Amount Excl GST"
                      value={data.Amount}
                      onChange={(e) => {
                        setData((a) => {
                          return { ...a, Amount: e };
                        });
                      }}
                    />
                  </InputGroup>
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Date : </label>
                  <br />
                  <InputGroup style={{ width: "250px" }}>
                    <InputGroup.Addon>GST (₹)</InputGroup.Addon>
                    <Input
                      readOnly={!editMode}
                      placeholder="GST"
                      value={data.GST}
                      onChange={(e) => {
                        setData((a) => {
                          return { ...a, GST: e };
                        });
                      }}
                    />
                  </InputGroup>
                </Row>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Notes : </label>
                  <Input
                    value={data.Notes}
                    readOnly={!editMode}
                    onChange={(e) => {
                      setData((a) => {
                        return {
                          ...a,
                          Notes: e,
                        };
                      });
                    }}
                    style={{ padding: 5, outline: "none" }}
                    placeholder="Enter Notes"
                  />
                </Row>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  {editMode ? (
                    <Button
                      style={{ padding: "10px 12px 10px 12px", width: 100 }}
                      appearance="primary"
                      color="blue"
                      onClick={UpdateData}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      style={{ padding: "10px 12px 10px 12px", width: 100 }}
                      appearance="primary"
                      color="blue"
                      onClick={() => {
                        setEditMode(true);
                      }}
                    >
                      Edit
                    </Button>
                  )}
                  &nbsp; &nbsp;
                  {editMode ? (
                    <Button
                      style={{ padding: "10px 12px 10px 12px", width: 100 }}
                      appearance="primary"
                      color="red"
                      onClick={() => {
                        setEditMode(false);
                      }}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      style={{ padding: "10px 12px 10px 12px", width: 100 }}
                      appearance="primary"
                      color="red"
                      onClick={() => {
                        handleDelete({ Name: data.Name, Date: data.Date });
                      }}
                    >
                      Delete
                    </Button>
                  )}
                </Row>
              </Col>
            </Row>
          </Grid>
        </div>
      </SideDrawer>
    </div>
  );
};

export default GSTCalculation;
