import React, { useState } from "react";
import {
  Input,
  useToaster,
  Notification,
  Tooltip,
  Whisper,
  InputGroup,
  Panel,
  List,
  Message,
  Col,
} from "rsuite";

import Header from "../component/Header";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; //
import { FlexboxGrid } from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import { ellipsify } from "../functions";
import { generateColor } from "../component/generateColor";
import { useSelector } from "react-redux";

const Inputs = () => {
  const user = useSelector((state) => {
    return state.user;
  });
  const [client, setClient] = React.useState(null);
  const [clientList, setClientList] = React.useState([]);
  const [supplier, setSupplier] = React.useState(null);
  const [supplierList, setSupplierList] = React.useState([]);
  const [site, setSite] = React.useState(null);
  const [siteList, setSiteList] = React.useState([]);
  const [payee, setPayee] = React.useState(null);
  const [payeeList, setPayeeList] = React.useState([]);

  /*   const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false); */
  const [processing, setProcessing] = useState(false);

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) refershallDropdown();
    return () => {
      isSubscribed = false;
    };
  }, []);

  const refershallDropdown = () => {
    GetClients();
    GetSuppliers();
    GetSites();
    GetPayee();
  };
  const toaster = useToaster();
  const deleteitem = (id, source) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    id,
                    source,
                  };
                  const response = await axios.post(
                    "https://api.laxmaninterior.in/RemoveItem.php",
                    datatosend
                  );
                  const result = response.data;
                  if (result.status === 1) {
                    refershallDropdown();
                    toaster.push(
                      <Message type="success">{result.Success}</Message>
                    );
                  }
                  onClose();

                  if (result.status === 0) {
                    toaster.push(
                      <Notification type="error" header="Error">
                        {`Error occured`}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  const GetClients = () => {
    setProcessing(true);

    axios
      .get("https://api.laxmaninterior.in/GetClients.php")
      .then(function (response) {
        const data1 = response.data;
        console.log(data1);
        if (data1.status === 1) {
          setClientList(data1.Data);
        }
        setProcessing(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing(false);
      });
  };

  const AddClient = async () => {
    setProcessing(true);

    if (client === "") {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Enter Client Name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    const data = {
      ClientName: client,
      Desc: client,
    };
    axios
      .post("https://api.laxmaninterior.in/AddClient.php", data, {
        headers: { "Content-type": "application/json" },
      })
      .then((response) => {
        const result = response.data;
        console.log(result);
        if (result.status === 1) {
          toaster.push(
            <Notification type="success" header="success" duration={1000}>
              {result.Success}
            </Notification>,
            { placement: "topEnd" }
          );

          // clearInput();
        }
        if (result.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              {`${client} Already exists`}
            </Notification>,
            { placement: "topEnd" }
          );
        }
        setProcessing(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing(false);
      });
  };

  const GetSuppliers = () => {
    setProcessing(true);

    axios
      .get("https://api.laxmaninterior.in/GetSuppliers.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setSupplierList(data1.Data);
        }
        if (data1.status === 0) {
          setSupplierList([]);
        }
        setProcessing(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing(false);
      });
  };

  const AddSupplier = async () => {
    setProcessing(true);

    if (supplier === "") {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Enter Supplier Name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    const data = {
      SupplierName: supplier,
      SupplierDesc: supplier,
    };
    axios
      .post("https://api.laxmaninterior.in/AddSupplier.php", data, {
        headers: { "Content-type": "application/json" },
      })
      .then((response) => {
        const result = response.data;
        console.log(result);
        if (result.status === 1) {
          toaster.push(
            <Notification type="success" header="success" duration={1000}>
              {result.Success}
            </Notification>,
            { placement: "topEnd" }
          );

          refershallDropdown();
          setProcessing(false);
        }
        if (result.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              {`${supplier} Already exists`}
            </Notification>,
            { placement: "topEnd" }
          );

          //clearInput();
          setProcessing(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setProcessing(false);
      });
  };

  const GetSites = () => {
    axios
      .get("https://api.laxmaninterior.in/GetSites.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setSiteList(data1.Data);
        }

        setProcessing(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing(false);
      });
  };

  const AddSite = async () => {
    setProcessing(true);

    if (site === "") {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Enter Site Name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    const data = {
      Name: site,
      Desc: site,
    };
    axios
      .post("https://api.laxmaninterior.in/AddSite.php", data, {
        headers: { "Content-type": "application/json" },
      })
      .then((response) => {
        const result = response.data;
        console.log(result);
        if (result.status === 1) {
          toaster.push(
            <Notification type="success" header="success" duration={1000}>
              {result.Success}
            </Notification>,
            { placement: "topEnd" }
          );
          refershallDropdown();
        }
        if (result.status === 2) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              {`${site} Already exists`}
            </Notification>,
            { placement: "topEnd" }
          );
        }
        setProcessing(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing(false);
      });
  };

  const GetPayee = () => {
    axios
      .get("https://api.laxmaninterior.in/GetPayee.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setPayeeList(data1.Data);
        }

        setProcessing(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing(false);
      });
  };

  const AddPayee = () => {
    const UniqueID = uuidv4();
    try {
      const datatoSend = {
        Name: payee,
        Color: generateColor(),
        Desc: payee,
        AddedBy: user.uid,
        UniqueID,
      };
      axios
        .post("https://api.laxmaninterior.in/AddBiller.php", datatoSend, {
          headers: { "Content-type": "application/json" },
        })
        .then((response) => {
          const result = response.data;
          if (result.status === 1) {
            toaster.push(
              <Notification type="success" header="Success" duration={1000}>
                Added successfully
              </Notification>,
              { placement: "topEnd" }
            );
            refershallDropdown();
          }
          if (result.status === 0) {
            toaster.push(
              <Notification type="error" header="Error" duration={1000}>
                {result.Error}
              </Notification>,
              { placement: "topEnd" }
            );
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Header>
        <h4 style={{ color: "white", fontSize: 25 }}>Manage Lists</h4>
      </Header>
      <div className="Subbody">
        <div className="show-grid">
          <FlexboxGrid justify="space-around">
            <FlexboxGrid.Item as={Col} colspan={5}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <InputGroup style={{ width: 300, marginBottom: 10 }}>
                  <Input
                    placeholder="Enter Client Name"
                    value={client}
                    onChange={setClient}
                  />
                  <InputGroup.Button onClick={AddClient}>
                    <PlusIcon />
                  </InputGroup.Button>
                </InputGroup>
                <div style={{ marginTop: 5, width: 300 }}>
                  <Panel header="Clients" bordered>
                    <List
                      size="sm"
                      style={{ maxHeight: 250, overflow: "auto" }}
                    >
                      {clientList.map((item, index) => (
                        <List.Item
                          key={item.id}
                          index={index}
                          style={{
                            fontSize: "1rem",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Whisper
                            followCursor
                            speaker={
                              <Tooltip
                                style={{ textTransform: "capitalize" }}
                              >{`${item.value}`}</Tooltip>
                            }
                          >
                            <p style={{ textTransform: "capitalize" }}>
                              {ellipsify(item.value)}
                            </p>
                          </Whisper>

                          <a
                            href="_blank"
                            style={{
                              paddingLeft: 5,
                              textDecoration: "none",
                              color: "red",
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              deleteitem(item.id, "Clients");
                            }}
                          >
                            X
                          </a>
                        </List.Item>
                      ))}
                    </List>
                  </Panel>
                </div>
              </div>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={5}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <InputGroup style={{ width: 300, marginBottom: 10 }}>
                  <Input
                    placeholder="Enter Supplier"
                    value={supplier}
                    onChange={setSupplier}
                  />
                  <InputGroup.Button onClick={AddSupplier}>
                    <PlusIcon />
                  </InputGroup.Button>
                </InputGroup>
                <div style={{ marginTop: 5, width: 300 }}>
                  <Panel header="Supplier" bordered>
                    <List
                      size="sm"
                      style={{ maxHeight: 250, overflow: "auto" }}
                    >
                      {supplierList.map((item, index) => (
                        <List.Item
                          key={item.id}
                          index={index}
                          style={{
                            fontSize: "1rem",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Whisper
                            followCursor
                            speaker={
                              <Tooltip
                                style={{ textTransform: "capitalize" }}
                              >{`${item.value}`}</Tooltip>
                            }
                          >
                            <p style={{ textTransform: "capitalize" }}>
                              {ellipsify(item.value)}
                            </p>
                          </Whisper>

                          <a
                            href="none"
                            style={{
                              paddingLeft: 5,
                              textDecoration: "none",
                              color: "red",
                              cursor: "pointer",
                            }}
                            onClick={() => deleteitem(item.id, "Supplier")}
                          >
                            X
                          </a>
                        </List.Item>
                      ))}
                    </List>
                  </Panel>
                </div>
              </div>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={5}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <InputGroup style={{ width: 300, marginBottom: 10 }}>
                  <Input
                    placeholder="Enter Site Name"
                    value={site}
                    onChange={setSite}
                  />
                  <InputGroup.Button onClick={AddSite}>
                    <PlusIcon />
                  </InputGroup.Button>
                </InputGroup>
                <div style={{ marginTop: 5, width: 300 }}>
                  <Panel header="Sites" bordered>
                    <List
                      size="sm"
                      style={{ maxHeight: 250, overflow: "auto" }}
                    >
                      {siteList.map((item, index) => (
                        <List.Item
                          key={item.id}
                          index={index}
                          style={{
                            fontSize: "1rem",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Whisper
                            followCursor
                            speaker={
                              <Tooltip
                                style={{ textTransform: "capitalize" }}
                              >{`${item.value}`}</Tooltip>
                            }
                          >
                            <p style={{ textTransform: "capitalize" }}>
                              {ellipsify(item.value)}
                            </p>
                          </Whisper>

                          <a
                            href="none"
                            style={{
                              paddingLeft: 5,
                              textDecoration: "none",
                              color: "red",
                              cursor: "pointer",
                            }}
                            onClick={() => deleteitem(item.id, "Sites")}
                          >
                            X
                          </a>
                        </List.Item>
                      ))}
                    </List>
                  </Panel>
                </div>
              </div>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={5}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <InputGroup style={{ width: 300, marginBottom: 10 }}>
                  <Input
                    placeholder="Enter Payee"
                    value={payee}
                    onChange={setPayee}
                  />
                  <InputGroup.Button onClick={AddPayee}>
                    <PlusIcon />
                  </InputGroup.Button>
                </InputGroup>
                <div style={{ marginTop: 5, width: 300 }}>
                  <Panel header="Payee" bordered>
                    <List
                      size="sm"
                      style={{ maxHeight: 250, overflow: "auto" }}
                    >
                      {payeeList.map((item, index) => (
                        <List.Item
                          key={item.id}
                          index={index}
                          style={{
                            fontSize: "1rem",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Whisper
                            followCursor
                            speaker={
                              <Tooltip
                                style={{ textTransform: "capitalize" }}
                              >{`${item.value}`}</Tooltip>
                            }
                          >
                            <p style={{ textTransform: "capitalize" }}>
                              {ellipsify(item.value)}
                            </p>
                          </Whisper>

                          <a
                            href="none"
                            style={{
                              paddingLeft: 5,
                              textDecoration: "none",
                              color: "red",
                              cursor: "pointer",
                            }}
                            onClick={() => deleteitem(item.id, "Billers")}
                          >
                            X
                          </a>
                        </List.Item>
                      ))}
                    </List>
                  </Panel>
                </div>
              </div>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </div>
      </div>
    </div>
  );
};

export default Inputs;
