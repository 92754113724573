import React, { useCallback, useState } from "react";
import {
  Button,
  Form,
  Radio,
  RadioGroup,
  SelectPicker,
  DateRangePicker,
  useToaster,
  Notification,
  IconButton,
  Stack,
  Tooltip,
  Row,
  Col,
  AutoComplete,
  Input,
  InputGroup,
  DatePicker,
  Grid,
  Toggle,
} from "rsuite";
import Header from "../component/Header";
import SearchIcon from "@rsuite/icons/Search";
import axios from "axios";
import moment from "moment";
import sendEmail from "../component/sendEmail";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { filterParams } from "../functions";
import AgGrid from "../component/AgGrid";
import useAPINew from "../Hooks/useAPINew";
import { addDays } from "date-fns";
import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import SideDrawer from "../component/SideDrawer";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";

//import { startOfToday } from "date-fns";
function getLastFinancialYearDates() {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();

  let startOfLastFY, endOfLastFY;

  if (currentMonth >= 3) {
    // From April to December
    startOfLastFY = new Date(currentYear - 1, 3, 1); // April 1st of last year
    endOfLastFY = new Date(currentYear, 2, 31); // March 31st of this year
  } else {
    // From January to March
    startOfLastFY = new Date(currentYear - 2, 3, 1); // April 1st two years ago
    endOfLastFY = new Date(currentYear - 1, 2, 31); // March 31st of last year
  }

  return {
    startOfLastFY,
    endOfLastFY,
  };
}

const { startOfLastFY, endOfLastFY } = getLastFinancialYearDates();

function getCurrentFinancialYearDates() {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();

  let startOfCurrentFY, endOfCurrentFY;

  if (currentMonth >= 3) {
    // From April to December
    startOfCurrentFY = new Date(currentYear, 3, 1); // April 1st of this year
    endOfCurrentFY = new Date(currentYear + 1, 2, 31); // March 31st of next year
  } else {
    // From January to March
    startOfCurrentFY = new Date(currentYear - 1, 3, 1); // April 1st of last year
    endOfCurrentFY = new Date(currentYear, 2, 31); // March 31st of this year
  }

  return {
    startOfCurrentFY,
    endOfCurrentFY,
  };
}

const { startOfCurrentFY, endOfCurrentFY } = getCurrentFinancialYearDates();

const predefinedRanges = [
  {
    label: "Today",
    value: [new Date(), new Date()],
  },
  {
    label: "Yesterday",
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
  },
  {
    label: "This week",
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
  },
  {
    label: "Last 7 days",
    value: [subDays(new Date(), 6), new Date()],
  },
  {
    label: "Last 30 days",
    value: [subDays(new Date(), 29), new Date()],
  },
  {
    label: "Last Fin Year",
    value: [startOfLastFY, endOfLastFY],
  },
  {
    label: "Current Fin Year",
    value: [startOfCurrentFY, endOfCurrentFY],
  },
  {
    label: "This month",
    value: [startOfMonth(new Date()), endOfMonth(new Date())],
  },
  {
    label: "Last month",
    value: [
      startOfMonth(addMonths(new Date(), -1)),
      endOfMonth(addMonths(new Date(), -1)),
    ],
  },
];
const ViewSuppliers = () => {
  const [radio, setRadio] = useState("Supplier");
  const [processing1, setProcessing1] = useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedNameID, setselectedNameID] = useState("");
  const [credit, setCredit] = useState(0);
  const [debit, setDebit] = useState(0);
  const usersList = useAPINew("GetSupervisorUsersNew.php");
  const siteList = useAPINew("GetSitesForDropdown.php", "Get");
  const [dataList, setdataList] = useState([]);
  const [dataList2, setdataList2] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [data, setData] = useState({ Date: new Date() });
  const [editMode, setEditMode] = React.useState(false);
  const [showDetails, setShowDetails] = React.useState(false);
  const [checked, setChecked] = useState(false);
  const [size, setSize] = useState("md");
  const [loading, setLoading] = useState(false);
  const [billCopy, setBillCopy] = useState("");
  const toggle = useCallback((props) => {
    setLoading(true);

    setTimeout(() => {
      setChecked((checked) => !checked);
      setLoading(false);
    }, 1000);

    return (
      <Toggle
        loading={loading}
        checked={checked}
        onChange={toggle}
        {...props}
      />
    );
  }, []);
  const [processing2, setProcessing2] = useState(false);
  //const [siteList, setSiteList] = useState([]);
  const SupplierList = useAPINew("GetSupplierNew.php", "Get");
  const [GST, setGST] = useState([]);
  const toaster = useToaster();
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [selectedDateRange, setselectedDateRange] = useState([
    new Date(),
    new Date(),
  ]);
  const [selectedSite, setselectedSite] = useState("");

  const [films1, setFilms1] = useState([]);

  const handleViewModalOpen = () => setOpenDrawer(true);
  const handleViewModalClose = () => {
    setOpenDrawer(false);
  };

  React.useEffect(() => {
    console.log(selectedRowData);
    let isSubscribed = true;
    if (isSubscribed)
      if (selectedRowData?.SrNo) {
        setChecked(false);
        // GetDiscussionByID(selectedRowData.UniqueID);
        var dateParts = selectedRowData?.Date.split("/");

        // month is 0-based, that's why we need dataParts[1] - 1
        var dateObject = new Date(
          dateParts[2],
          dateParts[1] - 1,
          +dateParts[0]
        );

        const newData = { ...selectedRowData, Date: new Date(dateObject) };
        setData(newData);
      }
    return () => {
      isSubscribed = false;
    };
  }, [selectedRowData?.SrNo, selectedRowData]);

  const getList = () => {
    //RetriveSiteName()
    axios
      .get("https://api.laxmaninterior.in/GetSiteName.php")
      .then((a) => {
        const result = a.data;
        if (result.status === 1) {
          //console.log(result.Data.map(({SiteName})=>SiteName))
          const test = result.Data.map(({ SiteName }) => SiteName);
          setFilms1(test);
          // console.log(result.Data)
        }
        if (result.status === 0) {
          setFilms1([]);
        }
        //console.log(result);
      })
      .catch((a) => {
        console.log(a);
      });
  };

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getList();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  function compare(a, b) {
    let nameA = a.toUpperCase();
    let nameB = b.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  const formatNumber = (number) => {
    return "₹ " + Math.floor(number).toLocaleString();
  };

  const currencyFormatter = (params) => {
    //return formatNumber(params.value);
    return formatNumber(params.value);
  };

  const column = React.useMemo(
    () => [
      { field: "SupplierName", title: "Supplier Name", minWidth: 300 },
      {
        field: "Date",
        title: "Date",
        type: "date",
        filter: "agDateColumnFilter",
        editable: false,
        filterParams: filterParams,
      },
      {
        field: "Credit",
        title: "Credit",
        valueFormatter: currencyFormatter,
        cellStyle: (params) => {
          if (params.value === 0) {
            //mark police cells as red
            return { color: "white", fontWeight: "bold" };
          }
        },
      },
      {
        field: "Debit",
        title: "Debit",
        valueFormatter: currencyFormatter,
        cellStyle: (params) => {
          if (params.value === 0) {
            //mark police cells as red
            return { color: "white", fontWeight: "bold" };
          }
        },
      },
      {
        field: "GST",
        title: "GST",
        valueFormatter: currencyFormatter,
        cellStyle: (params) => {
          if (params.value === 0) {
            //mark police cells as red
            return { color: "white", fontWeight: "bold" };
          }
        },
      },
      { field: "Total", title: "Total", valueFormatter: currencyFormatter },
      { field: "Sitename", title: "Sitename" },
      { field: "Notes", title: "Notes" },
    ],
    []
  );

  const column2 = React.useMemo(
    () => [
      { field: "SupplierName", title: "Supplier Name", minWidth: 300 },
      {
        field: "Date",
        title: "Date",
        type: "date",
        filter: "agDateColumnFilter",
        editable: false,
        filterParams: filterParams,
      },
      {
        field: "Credit",
        title: "Credit",
        valueFormatter: currencyFormatter,
        cellStyle: (params) => {
          if (params.value === 0) {
            //mark police cells as red
            return { color: "white", fontWeight: "bold" };
          }
        },
      },
      {
        field: "Debit",
        title: "Debit",
        valueFormatter: currencyFormatter,
        cellStyle: (params) => {
          if (params.value === 0) {
            //mark police cells as red
            return { color: "white", fontWeight: "bold" };
          }
        },
      },
      {
        field: "GST",
        title: "GST",
        valueFormatter: currencyFormatter,
        cellStyle: (params) => {
          if (params.value === 0) {
            //mark police cells as red
            return { color: "white", fontWeight: "bold" };
          }
        },
      },
      { field: "Total", title: "Total", valueFormatter: currencyFormatter },
      { field: "Sitename", title: "Sitename" },
      { field: "Notes", title: "Notes" },
    ],
    []
  );

  const tooltip = (
    <Tooltip placement="right" style={{ width: 500 }}>
      {`GST Received : ${Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 2,
      }).format(GST.Credit)}  Total : ${Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 2,
      }).format(GST.Credit + credit)}`}
      <br />
      {`GST Paid : ${Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 2,
      }).format(GST.Debit)}  Total : ${Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 2,
      }).format(GST.Debit + debit)}`}
      <br />
      {`Balance : ${Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 2,
      }).format(GST.Credit - GST.Debit)}  Total : ${Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 2,
      }).format(GST.Credit + credit - GST.Debit - debit)}`}
    </Tooltip>
  );

  const handleRadio = (e) => {
    setRadio(e);
    if (e === "Supplier") {
      SupplierList.reload();
    }
  };

  const handleDelete = (
    id,
    data = { Name: "", Date: "", Debit: "", Credit: "" }
  ) => {
    if (id === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Something went wrong.
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    confirmAlert({
      title: "Confirm?",
      message: `Are you sure to delete ${data.Name}'s Payment on ${
        data.Date
      } for ${data.Debit === "0" ? "Credit" : "Debit"} Amount ${
        data.Debit === "0" ? data.Credit : data.Debit
      }?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const datatoSend = { ID: id };
            console.log(datatoSend);
            axios
              .post(
                "https://api.laxmaninterior.in/DeleteFromSupplier.php",
                datatoSend
              )
              .then(function (response) {
                const data1 = response.data;
                if (data1.status === 1) {
                  toaster.push(
                    <Notification
                      type="success"
                      header="Deleted"
                      duration={1000}
                    >
                      Deleted successfully
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  sendEmail(
                    "magharamsuthar41@gmail.com",
                    `Deleted ${data.Name}'s Payment on ${
                      data.Date
                    } for Amount ${
                      data.Debit === "0" ? data.Credit : data.Debit
                    } ?`,
                    `Deleted from Supplier(${data.Name}) Transaction`,
                    "Successfully deleted"
                  );
                  sendEmail(
                    "akv7@ymail.com",
                    `Deleted ${data.Name}'s Payment on ${
                      data.Date
                    } for Amount Amount ${
                      data.Debit === "0" ? data.Credit : data.Debit
                    } ?`,
                    `Deleted from Supplier(${data.Name}) Transaction`,
                    "Successfully deleted"
                  );
                  if (radio === "Supplier") {
                    handleSearchforSupplier();
                  }
                  if (radio === "DateWise") {
                    handleSearchforSupplierByDate();
                  }
                  if (radio === "SiteWise") {
                    handleSearchBySite();
                  }
                  if (radio === "SiteSupplierWise") {
                    handleSearchBySupplierSite();
                  }
                }
                if (data1.status === 0) {
                  toaster.push(
                    <Notification type="error" header="Error" duration={1000}>
                      Something went wrong. Please refresh and try again later.
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  sendEmail(
                    "akv7@ymail.com",
                    "Something went wrong. Please refresh and try again later.",
                    `Delete from Supplier(${data.Name}) Transaction`,
                    "Error in Deleting from Supplier Transaction"
                  );
                }
              })
              .catch(function (error) {
                console.log(error);
                toaster.push(
                  <Notification type="error" header="Error" duration={1000}>
                    {error}
                  </Notification>,
                  { placement: "topEnd" }
                );
                sendEmail(
                  "akv7@ymail.com",
                  error,
                  `Delete from Supplier(${data.Name}) Transaction`,
                  "Error in Deleting from Supplier Transaction"
                );
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleSearchforSupplier = () => {
    setdataList([]);
    if (selectedNameID === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter Supplier name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    const datatoSend = { SupplierID: selectedNameID };
    console.log(datatoSend);
    axios
      .post(
        "https://api.laxmaninterior.in/GetTransactionsBySupplier.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setdataList(data1.Data);
          setCredit(data1.Credit);
          setDebit(data1.Debit);
          setGST(data1.GST);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              No records
            </Notification>,
            { placement: "topEnd" }
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        toaster.push(
          <Notification type="error" header="Error" duration={1000}>
            {error}
          </Notification>,
          { placement: "topEnd" }
        );
      });
  };

  const handleSearchBySite = () => {
    setdataList([]);
    if (selectedSite === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select site
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    const datatoSend = { Sitename: selectedSite };
    console.log(datatoSend);
    axios
      .post(
        "https://api.laxmaninterior.in/GetTrasactionsBySite.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setdataList(data1.Data);
          setCredit(data1.Credit);
          setDebit(data1.Debit);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              No records
            </Notification>,
            { placement: "topEnd" }
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        toaster.push(
          <Notification type="error" header="Error" duration={1000}>
            {error}
          </Notification>,
          { placement: "topEnd" }
        );
      });
  };

  const handleSearchBySupplierSite = () => {
    setdataList([]);
    if (selectedSite === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select site
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    const datatoSend = { Sitename: selectedSite, SupplierID: selectedNameID };
    //console.log(datatoSend);
    axios
      .post(
        "https://api.laxmaninterior.in/GetTransactionsBySiteSupplier.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setdataList(data1.Data);
          setCredit(data1.Credit);
          setDebit(data1.Debit);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              No records
            </Notification>,
            { placement: "topEnd" }
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        toaster.push(
          <Notification type="error" header="Error" duration={1000}>
            {error}
          </Notification>,
          { placement: "topEnd" }
        );
      });
  };

  const handleSearchforSupplierByDate = () => {
    setProcessing2(true);
    setdataList2([]);
    if (selectedDateRange.length < 2) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter Supplier name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    const datatoSend = {
      StartDate: moment(selectedDateRange[0]).format("YYYY-MM-DD"),
      EndDate: moment(selectedDateRange[1]).format("YYYY-MM-DD"),
    };
    axios
      .post(
        "https://api.laxmaninterior.in/GetSupplierTransactionByDate.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        //console.log(data1);

        if (data1.status === 1) {
          data1.Data.forEach((r) => {
            setdataList2(data1.Data);
            setCredit(data1.Credit);
            setDebit(data1.Debit);
          });
        }
        if (data1.status === 0) {
        }
        setProcessing2(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing2(false);
      });
  };

  const updateTransactions = () => {
    const formData = new FormData();
    formData.append("File", billCopy);
    setProcessing(true);

    if (data.Date === "" || data.Date === null) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please Select transaction date.
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (data.Amount === "" || data.Amount === null) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter bill amount.
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    try {
      if (billCopy) {
        setProcessing1(true);
        axios
          .post("https://downloads.laxmaninterior.in/AddBill.php", formData)
          .then((response) => {
            const result = response.data;
            setProcessing1(false);
            if (result.status === 1) {
              setProcessing(true);
              console.log(data);
              const datatoSend = {
                SupplierName: data.SupplierName,
                Notes: data.Notes,
                Amount: data.Amount,
                GST: data.GST,
                SupplierID: data.SupplierID,
                Date: moment(data.Date).format("YYYY-MM-DD"),
                Type: data.Type,
                Credit: data.Type === "Credit" ? data.Amount : 0,
                Debit: data.Type === "Debit" ? data.Amount : 0,
                Sitename: data.Sitename,
                SrNo: data.SrNo,
                Mode: data.Mode,
                Bill: result.Filename,
              };
              //return;
              axios
                .post(
                  "https://downloads.laxmaninterior.in/EditSupplierTransaction.php",
                  datatoSend,
                  {
                    headers: { "Content-type": "application/json" },
                  }
                )
                .then((response) => {
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Notification
                        type="success"
                        header="Success"
                        duration={1000}
                      >
                        Updated successfully
                      </Notification>,
                      { placement: "topEnd" }
                    );
                    setProcessing(false);
                    setEditMode(false);
                  }
                  if (result.status === 0) {
                    toaster.push(
                      <Notification type="error" header="Error" duration={1000}>
                        Error occured
                      </Notification>,
                      { placement: "topEnd" }
                    );
                    setProcessing(false);
                  }
                })
                .catch(function (error) {
                  console.log(error);
                  setProcessing(false);
                });
            }
            if (result.status === 0) {
              toaster.push(
                <Notification type="error" header="Error" duration={1000}>
                  {`Error occured - ${result.Error}`}
                </Notification>,
                { placement: "topEnd" }
              );
              setProcessing1(false);
            }
          })
          .catch(function (error) {
            console.log(error);
            setProcessing1(false);
          });
      } else {
        setProcessing(true);
        console.log(data);
        const datatoSend = {
          SupplierName: data.SupplierName,
          Notes: data.Notes,
          Amount: data.Amount,
          GST: data.GST,
          SupplierID: data.SupplierID,
          Date: moment(data.Date).format("YYYY-MM-DD"),
          Type: data.Type,
          Credit: data.Type === "Credit" ? data.Amount : 0,
          Debit: data.Type === "Debit" ? data.Amount : 0,
          Sitename: data.Sitename,
          SrNo: data.SrNo,
          Mode: data.Mode,
        };
        //return;
        axios
          .post(
            "https://api.laxmaninterior.in/EditSupplierTransaction.php",
            datatoSend,
            {
              headers: { "Content-type": "application/json" },
            }
          )
          .then((response) => {
            const result = response.data;
            if (result.status === 1) {
              toaster.push(
                <Notification type="success" header="Success" duration={1000}>
                  Updated successfully
                </Notification>,
                { placement: "topEnd" }
              );
              setProcessing(false);
            }
            if (result.status === 0) {
              toaster.push(
                <Notification type="error" header="Error" duration={1000}>
                  Error occured
                </Notification>,
                { placement: "topEnd" }
              );
              setProcessing(false);
              // handleClose4();
            }
          })
          .catch(function (error) {
            console.log(error);
            setProcessing(false);
          });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setProcessing(false);
      //  setProcessing1(false);
    }
  };

  return (
    <div>
      <Header>
        <h4 style={{ color: "white", fontSize: 25 }}>
          Suppliers Ledger -{radio === "Supplier" ? " Report by Supplier" : ""}
          {radio === "DateWise" ? " Date wise Search" : ""}
          {radio === "SiteWise" ? " Site wise Search" : ""}
          {radio === "SiteSupplierWise" ? " Site/Supplier wise" : ""}
        </h4>
      </Header>
      <div className="InputContainer">
        <Form.Group controlId="radioList">
          <RadioGroup
            name="radioList"
            inline
            appearance="picker"
            defaultValue="Supplier"
            onChange={(e) => {
              handleRadio(e);
            }}
            value={radio}
          >
            <Radio value="Supplier">Supplier</Radio>
            <Radio value="DateWise">Date wise</Radio>
            <Radio value="SiteWise">Site wise </Radio>
            <Radio value="SiteSupplierWise">Site & Supplier</Radio>
          </RadioGroup>

          <hr />
        </Form.Group>
        <Form layout="inline">
          {radio === "Supplier" && (
            <Stack
              spacing={10}
              style={{
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Stack spacing={4}>
                <SelectPicker
                  cleanable={false}
                  data={SupplierList.data}
                  appearance="subtle"
                  placeholder="Select Supplier"
                  width="auto"
                  value={selectedSupplier}
                  onSelect={(e, Item) => {
                    setSelectedSupplier(e);
                    setselectedNameID(Item.id);
                  }}
                />
                &nbsp;
                <IconButton
                  onClick={handleSearchforSupplier}
                  loading={processing}
                  icon={<SearchIcon />}
                  appearance="primary"
                  color="green"
                >
                  Search
                </IconButton>
                {dataList.length > 0 && (
                  <Button
                    appearance="primary"
                    onClick={() => setShowDetails(!showDetails)}
                  >
                    Detail
                  </Button>
                )}
              </Stack>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {showDetails && (
                  <div>
                    <Table size="small" aria-label="a dense table">
                      <TableBody>
                        <TableRow sx={{ backgroundColor: "grey" }}>
                          <TableCell>Type</TableCell>
                          <TableCell>Credit</TableCell>
                          <TableCell>Debit</TableCell>
                          <TableCell>Balance</TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>Amount Exl GST</TableCell>
                          <TableCell>{`${Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 2,
                          }).format(credit)}`}</TableCell>
                          <TableCell>{`${Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 2,
                          }).format(debit)}`}</TableCell>
                          <TableCell>{`${Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 2,
                          }).format(credit - debit)}`}</TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>GST</TableCell>
                          <TableCell>
                            {Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: "INR",
                              minimumFractionDigits: 2,
                            }).format(GST.Credit)}
                          </TableCell>
                          <TableCell>
                            {Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: "INR",
                              minimumFractionDigits: 2,
                            }).format(GST.Debit)}
                          </TableCell>
                          <TableCell>
                            {Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: "INR",
                              minimumFractionDigits: 2,
                            }).format(GST.Credit - GST.Debit)}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Total</TableCell>
                          <TableCell>{`${Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 2,
                          }).format(GST.Credit + credit)}`}</TableCell>
                          <TableCell>{`${Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 2,
                          }).format(GST.Debit + debit)}`}</TableCell>
                          <TableCell>{`${Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 2,
                          }).format(
                            GST.Credit + credit - GST.Debit - debit
                          )}`}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                )}
              </div>
            </Stack>
          )}

          {radio === "DateWise" && (
            <Stack spacing={10}>
              <Stack spacing={4}>
                <DateRangePicker
                  placeholder="Select Date Range"
                  cleanable={false}
                  value={selectedDateRange}
                  onChange={setselectedDateRange}
                  ranges={predefinedRanges}
                />
                &nbsp;
                <IconButton
                  onClick={handleSearchforSupplierByDate}
                  loading={processing2}
                  icon={<SearchIcon />}
                >
                  Search
                </IconButton>
              </Stack>
              <Stack spacing={4}>
                <div>
                  <span style={{ fontWeight: "bold" }}>Credit : </span>{" "}
                  {`${Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(credit)}`}
                </div>
                &nbsp; &nbsp;
                <div>
                  <span style={{ fontWeight: "bold" }}>Debit : </span>{" "}
                  {`${Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(debit)}`}
                </div>
              </Stack>
            </Stack>
          )}
          {radio === "SiteWise" && (
            <Stack spacing={10}>
              <Stack spacing={4}>
                <SelectPicker
                  cleanable={false}
                  data={siteList.data}
                  appearance="default"
                  placeholder="Select Site"
                  style={{ width: 250 }}
                  key="SrNo"
                  sort={() => {
                    return (a, b) => {
                      return compare(a.value, b.value);
                    };
                  }}
                  onSelect={(e, Item) => {
                    // console.log(Item);
                    setselectedSite(e);
                  }}
                  value={selectedSite}
                />
                <Button
                  color="blue"
                  appearance="default"
                  onClick={handleSearchBySite}
                >
                  <SearchIcon style={{ margin: "5px" }} /> Search
                </Button>
              </Stack>
              <Stack spacing={4}>
                <div>
                  <span style={{ fontWeight: "bold" }}>Credit : </span>{" "}
                  {`${Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(credit)}`}
                </div>
                &nbsp; &nbsp;
                <div>
                  <span style={{ fontWeight: "bold" }}>Debit : </span>{" "}
                  {`${Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(debit)}`}
                </div>
                &nbsp; &nbsp;
                <div>
                  <span style={{ fontWeight: "bold" }}>Balance : </span>{" "}
                  {`${Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(credit - debit)}`}
                </div>
              </Stack>
            </Stack>
          )}
          {radio === "SiteSupplierWise" && (
            <Stack spacing={10}>
              <Stack spacing={4}>
                <SelectPicker
                  cleanable={false}
                  data={siteList.data}
                  appearance="default"
                  placeholder="Select Site"
                  style={{ width: 200 }}
                  key="id"
                  sort={() => {
                    return (a, b) => {
                      return compare(a.value, b.value);
                    };
                  }}
                  onSelect={(e, Item) => {
                    // console.log(Item);
                    setselectedSite(e);
                  }}
                  value={selectedSite}
                />

                <SelectPicker
                  data={SupplierList.data}
                  cleanable={false}
                  appearance="default"
                  placeholder="Select Supplier"
                  style={{ width: 224 }}
                  value={selectedSupplier}
                  onSelect={(e, Item) => {
                    setSelectedSupplier(e);
                    setselectedNameID(Item.id);
                  }}
                />
                <Button
                  color="blue"
                  appearance="default"
                  onClick={handleSearchBySupplierSite}
                >
                  <SearchIcon style={{ margin: "5px" }} /> Search
                </Button>
              </Stack>
              <Stack spacing={4}>
                <div>
                  <span style={{ fontWeight: "bold" }}>Credit : </span>{" "}
                  {`${Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(credit)}`}
                </div>
                &nbsp; &nbsp;
                <div>
                  <span style={{ fontWeight: "bold" }}>Debit : </span>{" "}
                  {`${Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(debit)}`}
                </div>
                &nbsp; &nbsp;
                <div>
                  <span style={{ fontWeight: "bold" }}>Balance : </span>{" "}
                  {`${Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(credit - debit)}`}
                </div>
              </Stack>
            </Stack>
          )}
        </Form>
      </div>
      <div className="InputContainer">
        {radio === "Supplier" && (
          <>
            <AgGrid
              rowData={dataList}
              columns={column}
              onGridReady={handleSearchforSupplier}
              onRowSelectedData={setSelectedRowData}
              handleViewModalOpen={handleViewModalOpen}
              setRowStyle={true}
            />
          </>
        )}
        {radio === "DateWise" && (
          <>
            <AgGrid
              rowData={dataList2}
              columns={column2}
              onGridReady={handleSearchforSupplierByDate}
              onRowSelectedData={setSelectedRowData}
              handleViewModalOpen={handleViewModalOpen}
              setRowStyle={true}
            />
          </>
        )}
        {radio === "SiteWise" && (
          <>
            <AgGrid
              rowData={dataList}
              columns={column}
              onGridReady={handleSearchBySite}
              onRowSelectedData={setSelectedRowData}
              handleViewModalOpen={handleViewModalOpen}
              setRowStyle={true}
            />
          </>
        )}
        {radio === "SiteSupplierWise" && (
          <>
            <AgGrid
              rowData={dataList}
              columns={column2}
              onGridReady={handleSearchBySupplierSite}
              onRowSelectedData={setSelectedRowData}
              handleViewModalOpen={handleViewModalOpen}
              setRowStyle={true}
            />
          </>
        )}
      </div>
      <SideDrawer
        open={openDrawer}
        onClose={handleViewModalClose}
        title="View / Edit Transaction"
      >
        <div className="row">
          <Grid fluid style={{ padding: 1 }} gutter={4}>
            <Row className="show-grid">
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Name : </label>
                  <br />
                  <SelectPicker
                    readOnly={!editMode}
                    data={SupplierList.data}
                    cleanable={false}
                    appearance="default"
                    placeholder="Select Supplier"
                    value={data.SupplierName}
                    defaultValue={data.SupplierName}
                    onSelect={(e, Item) => {
                      setData((a) => {
                        return {
                          ...a,
                          SupplierName: Item.value,
                          SupplierID: Item.id,
                        };
                      });
                    }}
                  />
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Date : </label>
                  <br />
                  <DatePicker
                    readOnly={!editMode}
                    cleanable={false}
                    appearance="default"
                    placeholder="Select Date"
                    oneTap
                    format="dd-MM-yyyy"
                    style={{ width: "50%", fontSize: 17 }}
                    value={data.Date}
                    onChange={(e) => {
                      setData((a) => {
                        return {
                          ...a,
                          Date: e,
                        };
                      });
                    }}
                  />
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Type : </label>
                  <br />
                  <SelectPicker
                    readOnly={!editMode}
                    cleanable={false}
                    data={[
                      { id: 1, value: "Credit", label: "Credit" },
                      { id: 2, value: "Debit", label: "Debit" },
                    ]}
                    searchable={false}
                    appearance="subtle"
                    placeholder="Credit/Debit"
                    style={{
                      width: 150,
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderColor: "#eee",
                      outline: "none",
                    }}
                    value={data?.Type}
                    onChange={(e) => {
                      setData((a) => {
                        return { ...a, Type: e };
                      });
                    }}
                  />
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Amount Excl GST : </label>
                  <br />
                  <InputGroup style={{ width: "250px" }}>
                    <InputGroup.Addon>(₹)</InputGroup.Addon>
                    <Input
                      readOnly={!editMode}
                      placeholder="Amount Excl GST"
                      value={data.Amount}
                      onChange={(e) => {
                        setData((a) => {
                          return { ...a, Amount: e };
                        });
                      }}
                    />
                    <InputGroup.Addon>.00</InputGroup.Addon>
                  </InputGroup>
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Date : </label>
                  <br />
                  <InputGroup style={{ width: "250px" }}>
                    <InputGroup.Addon>GST (₹)</InputGroup.Addon>
                    <Input
                      readOnly={!editMode}
                      placeholder="GST"
                      value={data.GST}
                      onChange={(e) => {
                        setData((a) => {
                          return { ...a, GST: e };
                        });
                      }}
                    />
                    <InputGroup.Addon>.00</InputGroup.Addon>
                  </InputGroup>
                </Row>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Site Name : </label>
                  <AutoComplete
                    readOnly={!editMode}
                    data={films1}
                    value={data.Sitename}
                    onChange={(e) => {
                      setData((a) => {
                        return {
                          ...a,
                          Sitename: e,
                        };
                      });
                    }}
                    style={{ width: "100%" }}
                  />
                </Row>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Payment / Invoice By : </label>
                  <SelectPicker
                    data={usersList.data}
                    appearance="default"
                    readOnly={!editMode}
                    cleanable={false}
                    placeholder="Select from list"
                    style={{ width: 200 }}
                    key="id"
                    sort={() => {
                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    onSelect={(e, Item) => {
                      setData((a) => {
                        return {
                          ...a,
                          PaidByName: Item.label,
                          PaidBy: Item.value,
                        };
                      });
                    }}
                    value={data?.PaidBy}
                  />
                </Row>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Notes : </label>
                  <Input
                    readOnly={!editMode}
                    value={data.Notes}
                    onChange={(e) => {
                      setData((a) => {
                        return {
                          ...a,
                          Notes: e,
                        };
                      });
                    }}
                    style={{ padding: 5, outline: "none" }}
                    placeholder="Enter Notes"
                  />
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Attach Copy : </label>
                  <Toggle
                    readOnly={!editMode}
                    checked={checked}
                    checkedChildren="Bill"
                    unCheckedChildren="No Billl"
                    onChange={(e) => {
                      setChecked(e);
                    }}
                    size={size}
                  />
                  {checked && (
                    <input
                      type="file"
                      name="file"
                      onChange={(e) => setBillCopy(e.target.files[0])}
                    ></input>
                  )}
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  {!editMode && (
                    <>
                      <Button
                        style={{ padding: "10px 12px 10px 12px", width: 100 }}
                        appearance="primary"
                        onClick={() => {
                          setEditMode(true);
                        }}
                      >
                        Edit
                      </Button>
                    </>
                  )}
                  {editMode && (
                    <>
                      <Button
                        style={{ padding: "10px 12px 10px 12px", width: 100 }}
                        appearance="primary"
                        color="blue"
                        onClick={updateTransactions}
                      >
                        Save
                      </Button>
                    </>
                  )}
                  &nbsp; &nbsp;
                  {!editMode && (
                    <Button
                      style={{ padding: "10px 12px 10px 12px", width: 100 }}
                      appearance="primary"
                      color="red"
                      onClick={() => {
                        handleDelete(selectedRowData.SrNo, {
                          Name: selectedRowData.SupplierName,
                          Date: selectedRowData.Date,
                          Debit: selectedRowData.Amount,
                          Credit: selectedRowData.Amount,
                        });
                      }}
                    >
                      Delete
                    </Button>
                  )}
                  &nbsp; &nbsp;
                  {editMode && (
                    <Button
                      style={{ padding: "10px 12px 10px 12px", width: 100 }}
                      appearance="primary"
                      color="red"
                      onClick={() => {
                        setEditMode(false);
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                </Row>
              </Col>
              {selectedRowData?.Bill && (
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  style={{ margin: "6px 0px 6px 0px" }}
                >
                  <Row gutter={3} style={{ marginBottom: 10 }}>
                    <img
                      width={300}
                      alt="img"
                      src={`https://Downloads.laxmaninterior.in/upload/${selectedRowData?.Bill}`}
                    />
                  </Row>
                </Col>
              )}
            </Row>
          </Grid>
        </div>
      </SideDrawer>
    </div>
  );
};

export default ViewSuppliers;
